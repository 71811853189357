// 如果客服业务多个模块在使用建议放到这个公共文件中处理公共逻辑 防止后续改一个地方忘记改另一个地方
// 如果客服业务多个模块在使用建议放到这个公共文件中处理公共逻辑 防止后续改一个地方忘记改另一个地方
// 如果客服业务多个模块在使用建议放到这个公共文件中处理公共逻辑 防止后续改一个地方忘记改另一个地方
import { robotLinkCommon } from 'public/src/pages/common/utils/index.js'

const urlReg = /\b((https?):\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/\S*)?/g

// 批量查找替换
export function robotReplaceAll (str, key, val) {
  if (!str) return ''
  if (val && val.indexOf('$') >= 0) {
    return str.replace(new RegExp(key, 'ig'), val.replace(/\$/g, '$$$$'))
  } else {
    return str.replace(new RegExp(key, 'ig'), (val ? val : ''))
  }
}

// 校验敏感词
export const checkSensitiveTextarea = ({ sensitiveList = [], content = '' }) => {
  sensitiveList.forEach(itm => {
    content = robotReplaceAll(content, itm, `<span class="error-red" style="color: #BF4123">${itm}</span>`)
  })
  return content
}


/**
 * CSTK详情跳转渠道判断
 * @channel枚举值
 * 'chat': zd chat
 * 'call': 电话预约
 * 'ticket': zd tk
 * 'cstk': cs tk
 * 'csim': zd chat和cs chat
 * 'service order': 工单
 * 'service order tax': 关税工单
 * 'service order DeliveryNotReceived': 妥投工单
 */
export const isToCSTK = (channel) => {
  return ['csim', 'cstk'].includes(channel)
}

// 机器人跳转前存储url参数
export function jumpToRobot (name = 'navigationBar', cb) {
  let params = ''
  if (gbCommonInfo.robotStoreData && name != 'supportPage') {
    let { storeCode = '', pageName = '', storeType, business_model } = gbCommonInfo.robotStoreData
    
    if (name != 'NoLoginPage') name = pageName ? pageName : 'storePage'
    params = `&storecode=${storeCode}&business=${typeof business_model != 'undefined' ? business_model : (storeType == 1 ? 1 : 0)}`
  }
  // Vue route jump
  if (cb) {
    return cb(robotLinkCommon(name, params))
  }
  location.href = robotLinkCommon(name, params)
}

// 富文本内容包含链接转A标签
export const chatLinkHtml = (str) => {
  if (str.includes('<a ') || str.includes('<img ')) return str

  str = robotReplaceAll(str, /\n/g, '<br />')
  return str.replace(urlReg, match => {
    let urlWithProtocol = match.includes('://') ? match : `//${match}`
    return `<a style="text-decoration:underline" href="${urlWithProtocol}" target="_blank">${match}</a>`
  })
}


// 判断内容最后一句是否为链接
export const isLastCharacterInURL = (text) => {
  if (!urlReg.test(text)) {
    return false
  }

  // 获取最后一个字符
  const lastChar = text[text.length - 1]
  
  // 如果最后一个字符是空格或特殊符号，它不可能是URL的一部分
  if (!/\w/.test(lastChar)) {
    return false
  }

  // 使用match而不是exec
  const matches = text.match(urlReg)
  
  // 如果没有任何匹配项，最后一个字符不可能是URL的一部分
  if (!matches) {
    return false
  }

  // 检查最后一个字符是否在最后一个匹配的URL之中
  const lastMatch = matches[matches.length - 1]
  return text.endsWith(lastMatch)
}

