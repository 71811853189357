<template>
  <div
    class="payment_item_cod"
    @click.stop="clickItemCod"
  >
    <div class="chooce-payment-flexbetween">
      <div
        class="card-left"
        :class="{
          'flex-auto':
            banksConfig[payment.code] &&
            banksConfig[payment.code].list &&
            banksConfig[payment.code].list.length
        }"
      >
        <div class="card-left-select">
          <s-radio
            :value="
              !!(
                !pageStatus.cachePaymentsCode &&
                payment.checked &&
                !isDisabledPayment
              ) ||
                (pageStatus.cachePaymentsCode &&
                  pageStatus.cachePaymentsCode.code == payment.code &&
                  !isDisabledPayment)
            "
            :label="true"
            :disabled="
              isDisabledPayment || !paymentDisplay(payment.code) ? true : false
            "
            margin-r="0"
          />
        </div>
        <img
          v-if="!isDisabledPayment && paymentDisplay(payment.code)"
          class="payment-src"
          :src="transformImg({ img: getBankLogo(payment) || payment.logo_url })"
        />
        <img
          v-else
          class="payment-src"
          :src="transformImg({ img: payment.gray_logo_url })"
        />
        <span
          :class="[
            !isDisabledPayment && paymentDisplay(payment.code)
              ? ''
              : 'payment-disabled'
          ]"
        >
          {{ getPaymentTitle(payment) }}
        </span>
        <CodTips
          :language="language"
          :logo-url="payment.logo_url"
          @codTipExpose="codTipExpose"
          @clickCodTips="clickCodTips"
        />
        <template
          v-if="
            (lang == 'ar' || lang == 'ar-en') &&
              payment.code == 'cod' &&
              orderInfo.orderExtend &&
              orderInfo.orderExtend.codPrice &&
              Number(orderInfo.orderExtend.codPrice.amount) > 0
          "
        >
          <span class="off-tips cod-services-cost-tips">
            {{ language.SHEIN_KEY_PWA_15143 }}
            {{ orderInfo.orderExtend.codPrice.amountWithSymbol }}
          </span>
        </template>

        <!-- 0元门槛优惠 -->
        <s-label
          v-if="
            !isDisabledPayment &&
              onlinePayDiscountInfoValue(payment) &&
              onlinePayDiscountInfoMinAmount(payment) == 0
          "
          type="promo"
          class="payment-discount-zero"
        >
          <template
            v-if="onlinePayDiscountInfoValue(payment).discountType == 0"
          >
            <template v-if="GB_cssRight">
              {{
                language.SHEIN_KEY_PWA_14988 +
                  ' ' +
                  onlinePayDiscountInfoValue(payment).amount.amountWithSymbol
              }}
            </template>
            <template v-else>
              {{
                onlinePayDiscountInfoValue(payment).amount.amountWithSymbol +
                  ' ' +
                  language.SHEIN_KEY_PWA_14988
              }}
            </template>
          </template>
          <template
            v-if="onlinePayDiscountInfoValue(payment).discountType == 1"
          >
            <template v-if="GB_cssRight">
              {{
                language.SHEIN_KEY_PWA_14988 +
                  ' ' +
                  onlinePayDiscountInfoValue(payment).percent +
                  '%'
              }}
            </template>
            <template v-else>
              {{
                onlinePayDiscountInfoValue(payment).percent +
                  '% ' +
                  language.SHEIN_KEY_PWA_14988
              }}
            </template>
          </template>
        </s-label>
      </div>
      <template
        v-if="
          !isDisabledPayment &&
            paymentDisplay(payment.code) &&
            banksConfig[payment.code] &&
            banksConfig[payment.code].list &&
            banksConfig[payment.code].list.length
        "
      >
        <div class="order-bank-container">
          <div
            class="selected-bank-area"
            @click.stop.prevent="handleStartSelectBank(payment.code)"
          >
            <div
              class="selected-bank-text"
              :class="{ 'txt-error': banksConfig[payment.code].error }"
            >
              {{
                banksConfig[payment.code].bankCode &&
                  banksConfig[payment.code].bankCode !== -1
                  ? ''
                  : language.SHEIN_KEY_PWA_15965
              }}
            </div>
            <i class="suiiconfont sui_icon_more_down_16px icon2"></i>
          </div>
        </div>
      </template>
    </div>
    <PaymentCampTxt 
      v-if="paymentDes(payment)"
      v-show="!!(payment.code === 'cod' && (
        !pageStatus.cachePaymentsCode &&
        payment.checked &&
        !isDisabledPayment
      ) ||
        (pageStatus.cachePaymentsCode &&
          pageStatus.cachePaymentsCode.code == payment.code &&
          !isDisabledPayment)) || payment.code !== 'cod'"
      :collapse.sync="txtIsCamp"
      :txt="paymentDes(payment)"
    />
  </div>
</template>

<script>
import { template, transformImg } from '@shein/common-function'
import PaymentCampTxt from 'public/src/pages/checkout/vue_tpls/camp_txt/camp_txt.vue'
import CodTips from 'public/src/pages/checkout/vue_tpls/cod_tips.vue'
const { lang, GB_cssRight } = gbCommonInfo
const { IS_RW } = gbCommonInfo

export default {
  name: 'ChoocePaymentsItemCod',
  components: {
    PaymentCampTxt,
    CodTips
  },
  props: {
    payment: {
      type: Object,
      default: () => ({})
    },
    pageStatus: {
      type: Object,
      default: () => ({})
    },
    banksConfig: {
      type: Object,
      default: () => ({})
    },
    orderInfo: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    paymentDisplay: {
      type: Function,
      default: () => () => {}
    },
    getBankLogo: {
      type: Function,
      default: () => () => {}
    },
    getPaymentTitle: {
      type: Function,
      default: () => () => {}
    },
    paymentDes: {
      type: Function,
      default: () => () => {}
    },
    clickDes: {
      type: Function,
      default: () => () => {}
    },
    handleStartSelectBank: {
      type: Function,
      default: () => () => {}
    },
    onlinePayDiscountInfoValue: {
      type: Function,
      default: () => () => {}
    },
    onlinePayDiscountInfoMinAmount: {
      type: Function,
      default: () => () => {}
    }
  },
  data() {
    return {
      lang,
      GB_cssRight,
      IS_RW,
      isShowCamp: false,
      txtIsCamp: false
    }
  },
  computed: {
    isDisabledPayment() {
      return (
        this.payment.enabled === 0 || !this.paymentDisplay(this.payment.code)
      )
    }
  },
  methods: {
    template,
    transformImg,
    clickItemCod() {
      if (this.isDisabledPayment) {
        this.txtIsCamp = !this.txtIsCamp
      } else {
        this.$emit('clickItemCod')
      }
    },
    codTipExpose() {
      this.$emit('codTipExpose')
    },
    clickCodTips() {
      this.$emit('clickCodTips')
    }
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.payment_item_cod {
  display: flex;
  flex-direction: column;
}
</style>
