import orderLogic from 'public/src/pages/common/orderLogic'
import bankSelect from '../sub_modules/bank-select'
import schttp from 'public/src/services/schttp'

const state = {
  isSSR: false,
  pageLoading: true,
  locals: null,
  enterType: '',
  enterFailType: '',
  fromType: '',
  fromRecycle: '',
  orderType: '',
  billno: '',
  whatsAppSubStatusInfo: {},
  SHOP_TRANSIT: {},
  SECOND_LANGUAGE_COUNTRIES: [],
  MULTIPLE_TAXES_SPLIT_DISPLAY: [],
  subscribePhoneNumber: '',
  subscribed: -1, // 未订阅0 已订阅1
  showWhatsappSubscribe: '',
  isSubscribed: false,
  subscribe_value: '',
  language: {},
  orderInfo: {},
  extraBaseInfo: {},
  apolloInfo: {},
  orderResultAbtInfo: {},
  codOrderOverDueInfo: {},
  faqInfo: {},
  refundDetailInfo: {},
  detailNoticeInfo: {},
  orderInsuredInfo: {},
  exchangeInfo: {},
  isFreeRefund: false,
  editAddressNewConfigList: [],
  showPartOperatorBtn: true,
  pageStatus: {
    paymentDetailDrawer: false,
    editPayment: false,
    msg: false,
    reviewDrawer: false,
    showPaymentsDrawer: false,
    showBanksDrawer: false,
    bankDirection: 'right',
    cachePaymentsCode: '',
    isDirectPay: false,
    showGiftCardChangeEmail: false
  },
  msgBox: {
    info: '',
    type: ''
  },
  inReturnItem: {
    billno: '',
    mask: false
  },
  review: {
    product: {}
  },
  payments: [],
  cancelItemListFlag: false, // cancel item list 是否显示
  refundPageShowFlag: false, // 退款页面时候显示
  paymentMethod: { // 待支付界面显示的支付方式图标及title
    paymentLogo: '',
    paymentTitle: '',
    paymentMethod: '',
    paymentType: '' // 支付方式类型
  },
  // 更改前支付方式编码
  originPaymentMethod: {
    paymentMethod: ''
  },
  selectedIdealCode: '',
  selectBankCode: {
    'worldpay-ideal': '',
    'adyen-ideal': '',
    'adyen-eps': '',
    'Paytm-netbank': ''
  },
  applePayShow: false, // ios 13弹窗
  customsInterceptionPackages: {
    delay: [],
    processing: [],
    awaiting: [],
    rechange: [],
    refund: [],
    timeout: []
  }, // 海关拦截
  customsLang: {},
  customsOrder: {},
  paymentOption: {
    'Paytm-UPI': {
      payerAccount: '',
      showError: false
    },
    'cashfree-upi': {
      payerAccount: '',
      showError: false
    }
  },
  currencySupport: [],
  updateShippedAddress: {},
  trackOrderInfo: {},
  trackLang: {},
  isCopySuccess: false, // 物流 是否成功复制物流号
  saleTypeInfo: { // 新增巴西供应商判断
    selfSale: true, // 是否自营
    shipping_method_real: '', // 真实渠道
    shipping_no: '' // 运单号
  },
  giftcardChangeEmailVerificationCodeInfo: {}, //修改礼品卡邮箱 验证码信息
  giftcardChangeEmailOrResendPinSuccess: {}, //1：修改邮箱成功置灰修改邮箱以及重发卡密两按钮 2：重发卡密成功仅置灰重发按钮
  showShippingDayPercent: false,
  dayPercentInfo: {}, 
  noQuickshipCountry: [], // 阿波罗配置支持替换运输方式地址
  binDiscountInfo: {}, // 支付方式接口返回的卡bin优惠展示信息 bin_discount_info,
  binDiscountList: [], // 支付方式接口返回的卡bin优惠展示信息 bin_discount_list
  unPaidOrderLoadPayment: false, // 未支付订单是否加载支付方式
  isCurrentSite: false,
  customsAbtInfo: {}, // 海关ABT信息,
  couldModifyAddressData: {}, // 是否可编辑地址信息
  showBatchModify: false, // 是否展示批量修改地址
  fullPriceCouponPromotion: {},
  stateCitySecondLanguage: {
    state: '',
    city: '',
    district: ''
  },
}

const getters = {
  isCodPayment (state)  {
    return state.customsOrder?.payment_method === 'cod'
  },
  ordersStatus (state) {
    const { language } = state
    return orderLogic.orderStatus(language)
  },
  orderTime (state) {
    return state.orderInfo && state.orderInfo.orderTime
  },
  orderGoodsStatus (state) {
    const { language } = state
    return orderLogic.orderGoodsStatus(language)
  },
  getCodOrderFrom: () => () => {
    const siteInfo = {
      ar: ['ar', 'mar', 'pwar', 'pwaren', 'iosshsa', 'iosshkw', 'iosshae', 'iosshqa', 'iosshom', 'iosshbh', 'andshsa', 'andshkw', 'andshae', 'andshqa', 'andshom', 'andshbh'],
      my: ['my', 'mmy', 'ioshmy', 'andshmy'],
      tw: ['tw', 'mtw', 'iosshtw', 'andshtw'],
      in: ['in', 'min', 'pwin', 'iosshin', 'andshin', 'rwin', 'rwmin'],
      ph: ['ph', 'mph', 'iosshph', 'andshph'],
      vn: ['vn', 'mvn', 'iosshvn', 'andshvn'],
      ma: ['ma', 'pwma', 'iosshma', 'andshma'],
      id: ['id', 'mid', 'iosshid', 'andshid'],
      th: ['th', 'mth', 'iosshth', 'andshth'],

      rwar: ['rwar', 'rwmar', 'iosrwar', 'andrwar'],
    }
    //m站处理
    siteInfo['mar'] = siteInfo['pwar'] = siteInfo['pwaren'] = siteInfo['ar']
    siteInfo['mmy'] = siteInfo['my']
    siteInfo['mtw'] = siteInfo['tw']
    siteInfo['min'] = siteInfo['pwin'] = siteInfo['in']
    siteInfo['mph'] = siteInfo['ph']
    siteInfo['mvn'] = siteInfo['vn']
    siteInfo['pwma'] = siteInfo['ma']
    siteInfo['mid'] = siteInfo['id']
    siteInfo['mth'] = siteInfo['th']
    siteInfo['rwmar'] = siteInfo['rwar']

    const filterId = siteInfo[gbCommonInfo.SiteUID] || []
    return filterId
  }
}

const mutations = {
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  changeParamsStatus (state, { type, params }) {
    state[type] = { ...state[type], ...params }
  },
  changePageStatus (state, payload) {
    state.pageStatus = { ...state.pageStatus, ...payload }
  },
  handleEditPayment (state, payload = {}) {
    state.pageStatus.showPaymentsDrawer = true
    state.pageStatus.cachePaymentsCode = ''
    state.pageStatus.isDirectPay = !!payload.isDirectPay
  },
  handleChangePayment (state, payload) {
    state.paymentMethod = payload
  },
  setOriginPaymentMethod (state, payload) {
    state.originPaymentMethod = payload
  },
  showMsg (state, params) {
    var msg = params.msg
    var type = params.type
    var timer = params.timer || 1500

    state.pageStatus.msg = true
    state.msgBox.info = msg
    state.msgBox.type = type

    return new Promise(function (resolve) {
      setTimeout(function () {
        state.pageStatus.msg = false
        resolve()
      }, timer)
    })
  },
  resetCustomsTnterceptionPackages(state, payload) {
    state.customsInterceptionPackages = payload
  },
  storeCustomsTnterceptionPackages (state, payload) {
    state.customsInterceptionPackages = { ...state.customsInterceptionPackages, ...payload }
  },
  storeCustomsLang (state, payload) {
    state.customsLang = payload
  },
  storeCustomsOrder (state, payload) {
    state.customsOrder = payload
  },
  updateCustomsInterceptionPackagesState (state, { oringalKey = 'processing', key, index }) {
    const customsInterceptionPackages = state.customsInterceptionPackages
    customsInterceptionPackages[key] = customsInterceptionPackages[key] || []
    customsInterceptionPackages[key].unshift(customsInterceptionPackages[oringalKey][index])
    customsInterceptionPackages[oringalKey].splice(index, 1)
  },
  storeTrackOrderInfo(state, payload) {
    state.trackOrderInfo = { ...state.trackOrderInfo, ...payload }
  },
  storeTrackLang (state, payload) {
    state.trackLang = payload
  },
  updateCopyStatus(state, status) {
    state.isCopySuccess = status
  },
  updateSaleTypeInfo(state, data) {
    state.saleTypeInfo = Object.assign({}, state.saleTypeInfo, data)
  },
  resetBaseLocals (state) {
    if (state.locals) return
    if (typeof window === 'undefined') return

    let { lang, langPath, PUBLIC_CDN, WEB_CLIENT, IS_RW, IMG_LINK, GB_cssRight } = gbCommonInfo
    state.locals = { lang, langPath, PUBLIC_CDN, WEB_CLIENT, IS_RW, IMG_LINK, GB_cssRight }
  },
  resetSsrState(state) {
    state.isSSR = false

    if (gbCommonInfo?.contextForSSR) {
      delete gbCommonInfo.contextForSSR
    }

    if (window?.virtualOrdersDetailSsrData) {
      window.virtualOrdersDetailSsrData = null
    }
  },
  setSsrData (state, payload) {
    const {
      orderInfo,
      orderResultAbtInfo,
      extraBaseInfo,
      currencySupport,
      languageInfo,
      apolloInfo,
      isCurrentSite,
      whatsAppSubStatusInfo,
      verifyWhatsAppSubStatusInfo,
      codOrderOverDueInfo,
      faqInfo,
      refundDetailInfo,
      detailNoticeInfo,
      orderInsuredInfo,
      exchangeInfo,
      editAddressNewConfigList,
      isFreeRefund,
      fullPriceCouponPromotion,
      stateCitySecondLanguage,
      isSSR,
    } = payload || {}

    Object.assign(state, {
      isSSR,
      language: languageInfo || {},
      originPaymentMethod: { paymentMethod: orderInfo?.info?.order?.payment_method },
      paymentMethod: {
        paymentLogo: orderInfo?.info?.order?.paymentLogo, 
        paymentTitle: orderInfo?.info?.order?.paymentTitle || orderInfo?.info?.order?.payment_method, 
        paymentMethod: orderInfo?.info?.order?.payment_method,
        paymentType: orderInfo?.info?.order?.payment_type
      },
      selectedIdealCode: orderInfo?.info?.order?.payment_method || '',
      orderInfo: { ...(orderInfo?.info?.order || {}), orderCode: orderInfo?.code || '' },
      extraBaseInfo: extraBaseInfo || {},
      orderResultAbtInfo: orderResultAbtInfo || {},
      detailNoticeInfo: detailNoticeInfo || {},
      refundDetailInfo: refundDetailInfo || {},
      isSubscribed: verifyWhatsAppSubStatusInfo?.isSubscribed || false,
      subscribe_value: verifyWhatsAppSubStatusInfo?.subscribe_value || {},
      subscribePhoneNumber: whatsAppSubStatusInfo?.subscribeInfo?.phone || orderInfo?.shipping_telephone || '',
      subscribed: whatsAppSubStatusInfo?.subscribeInfo?.subscribe_state || 0,
      showWhatsappSubscribe: whatsAppSubStatusInfo?.showWhatsappSubscribe || '',
      whatsAppSubStatusInfo: whatsAppSubStatusInfo || {},
      currencySupport: currencySupport || [],
      apolloInfo: apolloInfo || {},
      isCurrentSite: isCurrentSite || false,
      codOrderOverDueInfo: codOrderOverDueInfo || {},
      faqInfo: faqInfo || {},
      orderInsuredInfo: orderInsuredInfo || {},
      exchangeInfo: exchangeInfo || {},
      isFreeRefund: isFreeRefund || false,
      editAddressNewConfigList: editAddressNewConfigList || [],
      updateShippedAddress: apolloInfo?.disabledAddress || {},
      noQuickshipCountry: apolloInfo?.noQuickshipCountry || [],
      SHOP_TRANSIT: apolloInfo?.SHOP_TRANSIT || {},
      SECOND_LANGUAGE_COUNTRIES: apolloInfo?.SECOND_LANGUAGE_COUNTRIES || [],
      MULTIPLE_TAXES_SPLIT_DISPLAY: apolloInfo?.MULTIPLE_TAXES_SPLIT_DISPLAY || [],
      fullPriceCouponPromotion: fullPriceCouponPromotion || {},
      stateCitySecondLanguage: stateCitySecondLanguage || {},
      pageLoading: false,
    })
  }
}

const actions = {
  //更新选中的银行
  updateSelectBank({ state }, opts) {
    const { code = '', item } = opts
    if( item && code ){
      state.selectBankCode[code] = item
    }
  },
  updateCachePayment({ state }, opts){
    const { index, code } = opts
    state.pageStatus.cachePaymentsCode = {
      index,
      code
    }
  },
  async initSsrData({ state, commit }, payload) {
    const { from, route, context } = payload
    const isSsrPage = !from?.name
    if (isSsrPage && context && context.ssrPageType === 'ordersDetail') {   
      commit('setSsrData', context)
      return
    }
    if (typeof window !== 'undefined') {
      commit('assignState', {
        orderInfo: {},
        pageLoading: true
      })
      const { billno = '' } = route?.params || {}
      const { orderType = '', type = '' } = route?.query || {}
      const res = await schttp({
        url: '/api/orders/base/orderDetail/query',
        params: {
          billno,
          withI18n: true,
          orderType,
          enterType: type,
        },
        schttp: {
          needLogin: true
        }
      })
      const {
        startTime,
        firstPromiseArrTime,
        secondPromiseArrTime,
      } = res || {}
      console.log('startTime========', startTime)
      console.log('firstPromiseArrTime========', firstPromiseArrTime, firstPromiseArrTime - startTime)
      console.log('secondPromiseArrTime========', secondPromiseArrTime, secondPromiseArrTime - startTime)
      commit('setSsrData', {
        ...res,
        isSSR: false,
      })
    }
    
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    bankSelect
  }
}
